import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Rating from 'react-rating';
import { Col, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import { saveItem, unsaveItem } from '../../pages/SavedItems/helper';
import Placeholder from '../../../config/placeholder.config';
import PlaceholderDark from '../../../config/placeholder-dark.config';
import { deleteProductReview as deleteProductReviewAction } from '../../pages/ProductReviewEditor/actions';
import Icon from '../Icon';
import ShareItem from '../../models/ShareItem';
import ShareIntent from '../../utils/shareUtils';
import MediaGrid from '../../components/media-grid';
import VideoSource from '../VideoSource';
import { renderTimestamp } from '../../utils/formatting';
import ModalDialog from '../ModalDialog';
import { config } from '../../settings';
import BottomSheet from '../bottom-sheet';
import FeedCardActions from '../FeedCardActions';
import { SettingsStore, ShopStore } from '../../stores';
import { addToast as actionAddToast } from '../../actions';
import Product from '../../models/Product';
import { Resize } from '../../utils/imageUtils';
import axios from 'axios';
import { redirectAuth } from '../../utils/redirect';
import Venue from '../../models/Venue';
import { language, messages } from '../../utils/localeUtils';
import { EmptySquareStar, FilledSquareStar } from '../Ratings';

const hashids = new Hashids('', 12);

export function FeedProductReviewCard(props) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const itemSaved = ShopStore.useState((s) => s.products.saved);

  const reviewImage = () => {
    const { review } = props;
    if (review.media && review.media.length) {
      for (let i = 0; i < review.media.length; i++) {
        const m = review.media[i];
        if (m.media_type === 'image') {
          return Resize.size(m.media_url);
        }
      }
    }
    return Product.getImage(props.review.product);
  };

  const [moreOptions, setMoreOptions] = useState([{
    content: itemSaved ? 'Unsave this product' : 'Save this product',
    icon: 'bookmark',
    value: 'save',
    onClick: () => {
      if (props.auth && props.auth.user) {
        if (itemSaved) {
          unsaveItem(props.auth.user, {
            item_id: props.review.product.id,
            type: 'product',
          });
          props.addToast({
            content: (<>Removed '{props.review.product.name}' from saved items</>),
            duration: 6000,
          });
        } else {
          saveItem(props.auth.user, 'product', props.review.product);
          props.addToast({
            content: (<>Saved '{props.review.product.name}'</>),
            duration: 6000,
          });
        }
      } else {
        // TODO Can we trigger the save when it returns?
        redirectAuth(`${window.location.href}?action=save&itemId=${props.review.product.id}`);
      }
    },
  }, {
    content: 'Shop Now',
    icon: 'shopping-cart',
    value: 'products',
    onClick: () => {
      const { product } = props.review;
      props.history.push({ pathname: `/products/${hashids.encode(product.id)}`, state: { product } });
    },
  }, {
    content: 'Share',
    icon: 'share',
    value: 'share',
    onClick: () => {
      const { product } = props.review;
      ShareIntent.share(new ShareItem({
        title: product.name,
        text: review.comment,
        path: 'product',
        route: `/products/${hashids.encode(product.id)}?reviewId=${hashids.encode(review.id)}`,
        image: reviewImage(),
      }));
    },
  }, {
    content: 'Embed',
    icon: 'code',
    value: 'embed',
    onClick: () => {
      window.open(`${config.embedEndPoint}?query=${config.appUrl}/products/${hashids.encode(props.review.product.id)}?reviewId=${hashids.encode(props.review.id)}`, '_blank');
    },
  }]);

  useEffect(() => {
    // FIXME Can this be simplified?
    if ((props.review && props.review && props.review.user && props.review.user.id) === (props.auth && props.auth.user && props.auth.user.id)) {
      setMoreOptions([...moreOptions, ...[{
        content: 'Edit Review',
        value: 'edit',
        onClick: () => {
          const { review } = props;
          const { product } = review;
          props.history.push({
            pathname: `/products/${hashids.encode(product.id)}`,
            search: `action=review&itemId=${product.id}&editId=${review.id}`,
            state: { review },
          });
        },
      }, {
        content: 'Delete Review',
        value: 'delete',
        style: {
          color: '#ef5164',
        },
        onClick: () => {
          ModalDialog.show({
            title: 'Delete Review',
            message: 'Are you sure you want to delete this review?',
            buttons: [{
              label: 'Cancel',
              onClick: () => {
                ModalDialog.close();
              },
            }, {
              label: 'Delete',
              style: { color: 'rgb(239, 81, 100)' },
              color: 'danger',
              onClick: () => {
                props.deleteReview(props.review);
                ModalDialog.close();
              },
            }],
            onClose: () => {
              ModalDialog.close();
            },
          });
        },
      }]]);
    } else {
      setMoreOptions([...moreOptions, {
        content: 'Report Post',
        value: 'report',
        onClick: () => {
          console.log('TODO Send email to our admins with the details?');
        },
      }]);
    }
  }, []);

  useEffect(() => {
    const { product } = review;
    if (product) {
      window.analytics?.track('Product Impressions', {
        product_id: product.id,
        venue_id: product.venue_id,
        user_id: props.auth && props.auth.user && props.auth.user.id,
      });
    }
  }, [review && review.product]);

  const trackVenueClick = (venue) => {
    if (window.analytics) {
      window.analytics.track('Venue Clicks', {
        venue_id: venue.id,
        user_id: props.auth && props.auth.user && props.auth.user.id,
        impression_type: 'user-generated',
      });
    }
  };

  const getImage = () => {
    const { review } = props;
    let imageUrl = Placeholder.product;

    if (review.image_url) {
      imageUrl = review.image_url;
    } else if (review.image_url) {
      imageUrl = review.image_url;
    }

    return imageUrl;
  };

  const showProductDetail = (product) => {
    if (product && product.id) {
      window.analytics?.track('Product Clicks', {
        product_id: product.id,
        venue_id: product.venue_id,
        user_id: props.auth && props.auth.user && props.auth.user.id,
      });
      props.history.push({ pathname: `/products/${hashids.encode(product.id)}`, state: { product } });
    }
  };

  const userName = () => {
    const { review } = props;
    if (review.user.alias) {
      return review.user.alias;
    }
    if (review.user.full_name) {
      return review.user.full_name;
    }
    return `${review.user.first_name} ${review.user.last_name}`;
  };

  const renderHeader = () => {
    const { review } = props;
    return (
      <div style={{ display: 'flex', padding: 5 }} className="feed-review-card-header">
        <Link to={`/users/${hashids.encode(review.user.id)}`}>
          <Avatar
            src={review.user.image_url}
            alt={review.user.alias || review.user.display_name}
            style={{ height: 28, width: 28, margin: '8px 12px' }}
          >
            {review.user.first_name.charAt(0)}
          </Avatar>
        </Link>
        <div
          style={{ flex: 1 }}
          itemProp="author"
          itemScope
          itemType="https://schema.org/Person"
        >
          <Link to={`/users/${hashids.encode(review.user.id)}`}>
            <div style={{ fontWeight: 600, color: '#2b2b2b !important' }} itemProp="name">{userName()}</div>
          </Link>
          <div style={{ fontSize: 12 }}>
            {`Reviewed ${renderTimestamp(review.timestamp)}`}
          </div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="More Options"
          style={{ marginRight: 5 }}
          onClick={() => {
            BottomSheet.show({
              items: moreOptions,
            });
          }}
        >
          <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
        </IconButton>
      </div>
    );
  };

  const renderMedia = () => (
    <div className="image">
      { renderHeader() }
      { renderInnerMedia() }
    </div>
  );

  const renderInnerMedia = () => {
    const { review } = props;
    if (review.media && review.media.length > 0) {
      if (review.media[0].media_type === 'video') {
        return (
          <VideoSource
            videoSrc={review.media[0].media_url}
            width="350"
            height="233"
            placeholder={review.media[0].thumbnail_url}
          />
        );
      }
      return (
        <MediaGrid
          media={review.media.map((media) => ({ type: media.media_type, src: media.media_url }))}
          lazyImages={review.media.map((media) => ({ type: media.media_type, src: Resize.size(media.media_url, { height: 100, width: 100, croptType: 'crop' }) }))}
          thumbnailMedia={review.media.map((media) => ({ type: media.media_type, src: Resize.size(media.media_url) }))}
          onClick={() => showProductDetail(review.product)}
        />
      );
    }
    // if (review.video_url) {
    //   return (
    //     <VideoSource
    //       videoSrc={review.video_url}
    //       width="350"
    //       height="233"
    //       // placeholder={review.video_url} // FIXME First frame from video
    //       // title={post.title}
    //     />
    //   );
    // }
    return (
      <Link to={`/products/${hashids.encode(review.product.id)}`}>
        <div className="img" style={{ backgroundImage: `url("${getImage()}"), url("${darkMode ? PlaceholderDark.band : Placeholder.band}")` }} />
      </Link>
    );
  };

  const renderAverageRating = () => {
    const { review, avgRating, totalRatings } = props;
    let rating = avgRating;
    if (typeof rating === 'boolean') {
      rating = review.product.avg_rating;
    }
    return (
      <div>
        <span>Average rating</span>
        <Rating
          initialRating={rating / 20}
          emptySymbol={<EmptySquareStar />}
          fullSymbol={<FilledSquareStar />}
          fractions={2}
          readonly
          style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginLeft: 10 }}
        />
        <span style={{ marginLeft: 10 }}>
          {totalRatings || review.product.total_ratings || 0}
          {' '}
          ratings
        </span>
      </div>
    );
  };

  const renderRating = () => {
    const { review } = props;
    const advancedRating = review.advance_rating;
    return (
      <div>
        <div style={{ display: 'none' }} itemProp="reviewRating" itemScope itemType="https://schema.org/Rating">
          <span itemProp="ratingValue">{advancedRating.rating}</span>
        </div>
        <Row>
          <Col>
            <div>Packaging</div>
            <div>Flavor</div>
            <div>Consistency</div>
            <div>Price</div>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <div>
              <Rating
                initialRating={advancedRating.packaging || advancedRating.rating}
                emptySymbol={<EmptySquareStar />}
                fullSymbol={<FilledSquareStar />}
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
            <div>
              <Rating
                initialRating={advancedRating.flavor || advancedRating.rating}
                emptySymbol={<EmptySquareStar />}
                fullSymbol={<FilledSquareStar />}
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
            <div>
              <Rating
                initialRating={advancedRating.consistency || advancedRating.rating}
                emptySymbol={<EmptySquareStar />}
                fullSymbol={<FilledSquareStar />}
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
            <div>
              <Rating
                initialRating={advancedRating.price || advancedRating.rating}
                emptySymbol={<EmptySquareStar />}
                fullSymbol={<FilledSquareStar />}
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderComment = () => (
    <div style={{ display: 'flex', marginTop: 20 }}>
      <Icon name={['fas', 'quote-left']} vendor="fa" style={{ color: '#d9d9d9', width: 24 }} />
      <div style={{ flex: 'auto', marginLeft: 6, marginBottom: 10 }} itemProp="reviewBody">
        {props.review.comment}
      </div>
    </div>
  );

  const renderPurchaseLocation = () => {
    const { review } = props;
    const { product } = review;
    const purchaseLocation = product.venue;
    if (purchaseLocation) {
      // FIXME Using virtual lists, can't this be inaccurate? Disabling for now to avoid issues with Segment
      // if (window.analytics) {
      //   window.analytics.track('Venue Impressions', {
      //     venue_id: review.product.venue_id,
      //     user_id: props.auth && props.auth.user && props.auth.user.id,
      //     impression_type: 'user-generated',
      //   });
      // }
      return (
        <div
          style={{
            display: 'flex',
            marginTop: 8,
          }}
        >
          <Link
            to={`/venues/${purchaseLocation.hash_id}`}
            style={{ display: 'flex' }}
            onClick={() => trackVenueClick(purchaseLocation)}
          >
            <Avatar
              src={Venue.getImage(purchaseLocation)}
              style={{
                height: 20,
                width: 20,
                marginRight: 10,
              }}
              alt={purchaseLocation.name}
            >
              {purchaseLocation.name.charAt(0)}
            </Avatar>
            <div style={{ flex: 'auto' }}>
              {`${messages[language]?.sold_by || 'Sold by'} ${purchaseLocation.name}`}
            </div>
          </Link>
        </div>
      );
    }
    return null;
  };

  const { review, showTitle } = props;
  console.log('review.product');
  console.log(review.product);
  const hasMedia = review.image_url || review.video_url || review.image_url || (review.media && review.media.length > 0);
  return (
    <div className={`feed-card-item ${props.paddedBottom ? 'card-padded-bottom' : ''}`} itemProp="review" itemScope itemType="https://schema.org/Review">
      { (hasMedia) && renderMedia() }
      <div className="content">
        { !hasMedia && renderHeader() }
        <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
          <div style={{ display: 'flex' }}>
            {showTitle && (
              <Avatar
                src={Product.getImage(review.product)}
                alt={review.product.name}
                style={{
                  height: 60,
                  width: 60,
                  margin: 10,
                }}
              />
            )}
            <div
              style={{ display: 'none' }}
              itemType="https://schema.org/Product"
              itemProp="itemReviewed"
              itemScope
            >
              <span itemProp="name">{Product.title(review.product)}</span>
              <div
                itemType="https://schema.org/AggregateRating"
                itemProp="aggregateRating"
                itemScope
              >
                <meta itemProp="bestRating" content="100" />
                <span itemProp="ratingValue">{review.product.avg_rating}</span>
                <span itemProp="ratingCount">{props.totalRatings || review.product.total_ratings || 0}</span>
              </div>
            </div>
            <span onClick={() => showProductDetail(review.product)}>
              <h3 className="link" style={{ marginBottom: 0 }}>
                {showTitle && Product.title(review.product)}
              </h3>
              <div style={{ marginBottom: 20 }}>{Product.packageTitle(review.product)}</div>
            </span>
          </div>
          <div>
            { props.avgRating && renderAverageRating() }
            {/* { isMobile && showTitle && renderActionIcons() } */}
          </div>
          { review.advance_rating && renderRating() }
          { review.comment && renderComment() }
          { review.product.venue && renderPurchaseLocation()}
        </div>
        <FeedCardActions
          postId={review.id}
          commentType="product_review"
          commentRoute="products/reviews"
          commentCount={props.commentCount}
          likeCount={props.likeCount}
          commentOnClick={props.compact ? () => {
            // FIXME Just pass it no matter what?
            if (typeof props.onCommentClick === 'function') {
              props.onCommentClick();
            }
          } : null}
          onLikedToggle={(liked) => {
            console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          onPostComment={(comment, parentId) => {
            console.log('Comment posted...');
            console.log(comment);
            axios.post(`${config.shopEndPoint}/products/reviews/${review.id}/comments`, {
              product_review_id: review.id,
              // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
              comment: comment.replace('<br>', ''),
              user_id: props.auth.user.id,
              parent_id: parentId,
              comment_timestamp: new Date().toISOString(),
            });
          }}
          // FIXME Probably share the review moving forward - take users to the comment screen?
          onShare={() => ShareIntent.share(new ShareItem({
            title: review.product.name,
            text: `View my latest review of ${review.product.name} on Cigars Near Me`,
            path: 'product',
            route: `/products/${hashids.encode(review.product.id)}?reviewId=${hashids.encode(review.id)}`,
            image: reviewImage(),
          }))}
        />
      </div>
    </div>
  );
}

FeedProductReviewCard.propTypes = {
  review: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  avgRating: PropTypes.bool || PropTypes.number,
  totalRatings: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
    deleteReview: (review) => dispatch(deleteProductReviewAction(review)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedProductReviewCard));
