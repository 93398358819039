import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Rating from 'react-rating';
import { Col, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import axios from 'axios';
import { loadModule } from 'cld3-asm';
import { saveItem, unsaveItem } from '../../pages/SavedItems/helper';
import Placeholder from '../../../config/placeholder.config';
import PlaceholderDark from '../../../config/placeholder-dark.config';
import Icon from '../Icon';
import ShareItem from '../../models/ShareItem';
import ShareIntent from '../../utils/shareUtils';
import MediaGrid from '../../components/media-grid';
import { formatDuration, renderTimestamp } from '../../utils/formatting';
import { deleteSession as deleteSessionAction } from '../../pages/SessionEditor/actions';
import ModalDialog from '../ModalDialog';
import BottomSheet from '../bottom-sheet';
import { language, messages } from '../../utils/localeUtils';
import { addToast as actionAddToast } from '../../actions';
import FeedCardActions from '../FeedCardActions';
import Drink from '../../models/Drink';
import { config } from '../../settings';
import { CigarStore, SettingsStore } from '../../stores';
import Cigar from '../../models/Cigar';
import { Resize } from '../../utils/imageUtils';
import Venue from '../../models/Venue';
import { redirectAuth } from '../../utils/redirect';
import { Chip } from '@material-ui/core';
import Linkify from 'linkify-react';
import { EmptySquareStar, FilledSquareStar } from '../Ratings';

const hashids = new Hashids('', 12);
const getLanguage = () => ((navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language);

export function FeedSessionCard(props) {
  const [showTranslation, setShowTranslation] = useState(false);
  const [translation, setTranslation] = useState(null);
  // FIXME When would this ever be the case?
  if (!props.session.scan) {
    console.log('Missing scan on session: ', props.session.id);
    return null;
  }
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const itemSaved = CigarStore.useState((s) => s.saved.indexOf(parseInt(props.session.scan.cigar.id)) !== -1);
  const itemFavorited = CigarStore.useState((s) => s.favorites.indexOf(parseInt(props.session.scan.cigar.id)) !== -1);
  const [moreOptions, setMoreOptions] = useState([{
    content: itemSaved ? 'Remove from Try List' : 'Save to Try List',
    icon: 'bookmark',
    value: 'try_list',
    onClick: () => {
      const { cigar } = props.session.scan;
      const { auth } = props;
      const { user } = auth;
      if (user && user.id) {
        if (itemSaved) {
          unsaveItem(user, {
            item_id: cigar.id,
            type: 'cigar',
            collection_type: 'try_list',
          });
          props.addToast({
            content: (
              <>
                {`Removed '${cigar.full_name}' from Try List`}
              </>),
            duration: 6000,
          });
        } else {
          saveItem(user, 'cigar', cigar, 'try_list');
          props.addToast({
            content: (
              <>
                {`Saved '${cigar.full_name}' to Try List for later`}
              </>),
            duration: 6000,
          });
        }
      } else {
        redirectAuth(`${window.location.href}?action=save&item_id=${cigar.id}&collection=try_list`, false);
      }
    },
  }, {
    content: itemFavorited ? 'Remove from Favorites' : 'Mark as Favorite',
    icon: 'heart',
    value: 'favorite',
    onClick: () => {
      const { cigar } = props.session.scan;
      const { auth } = props;
      const { user } = auth;
      if (user && user.id) {
        if (itemFavorited) {
          unsaveItem(user, {
            item_id: cigar.id,
            type: 'cigar',
            collection_type: 'favorites',
          });
          props.addToast({
            content: (
              <>
                {`Removed '${cigar.full_name}' from Favorites`}
              </>
            ),
            duration: 6000,
          });
        } else {
          saveItem(user, 'cigar', cigar, 'favorites');
          props.addToast({
            content: (
              <>
                {`Marked '${cigar.full_name}' as Favorite`}
              </>
            ),
            duration: 6000,
          });
        }
      } else {
        redirectAuth(`${window.location.href}?action=save&item_id=${cigar.id}&collection=favorites`, false);
      }
    },
  }, {
    content: 'Show Where To Buy',
    icon: 'shopping-cart',
    value: 'products',
    onClick: () => {
      const { cigar } = props.session.scan;
      props.history.push({
        pathname: `/cigars/${hashids.encode(cigar.id)}?tab=products`,
        state: { cigar },
      });
    },
  },
  {
    content: 'Embed',
    icon: 'code',
    value: 'embed',
    onClick: () => {
      window.open(`${config.embedEndPoint}?query=${config.appUrl}/cigars/${hashids.encode(props.session.scan.cigar.id)}?sessionId=${hashids.encode(props.session.id)}`, '_blank');
    },
  }]);

  useEffect(() => {
    if (props.session.comment) {
      (async () => {
        // FIXME I think this should be a helper function called "shouldTranslate" - we really just need to know if we
        //  should try to fetch a translation or not - that way, this can also be used for comments on items
        const cldFactory = await loadModule();
        const identifier = cldFactory.create(0, 1000);
        const result = identifier.findLanguage(props.session.comment);
        const lang = result.language;
        console.log('Language detected:');
        console.log(lang);
        const userLang = getLanguage().split('-')[0];
        console.log('Users language:');
        console.log(userLang);
        if (userLang !== lang) {
          axios.post(`${config.apiEndPoint}/feed/translations`, {
            text: props.session.comment,
            target_lang: userLang.toUpperCase(),
          }).then((response) => {
            if (response.data && response.data.translation && response.data.translation.text) {
              // FIXME Sometimes, it translates the text even if it doesn't need to - for now, just prevent it from showing
              if (response.data.translation.text.trim() !== props.session.comment.trim()) {
                setTranslation(response.data.translation.text);
                setShowTranslation(true);
              }
            }
          });
        }
      })();
    }
  }, [props.session && props.session.comment]);

  // useEffect(() => {
  //   if (props.session.comment && translation) {
  //     if (props.session.comment !== translation) {
  //       // They are different languages, show button
  //     }
  //   }
  // }, [props.session && props.session.comment, translation]);

  // FIXME These don't push after one of the toggle items are updated
  useEffect(() => {
    // FIXME Can this be simplified?
    if ((props.session && props.session.scan && props.session.scan.user && props.session.scan.user.id) === (props.auth && props.auth.user && props.auth.user.id)) {
      setMoreOptions([...moreOptions, ...[{
        content: 'Edit Session',
        icon: 'edit',
        value: 'edit',
        onClick: () => {
          const { session } = props;
          const { cigar } = session.scan;
          props.history.push({
            pathname: `/cigars/${hashids.encode(cigar.id)}`,
            search: `action=smokenow&itemId=${cigar.id}&editId=${session.id}`,
            state: { session },
          });
        },
      }, {
        content: 'Delete Session',
        value: 'delete',
        icon: 'trash',
        style: { color: '#ef5164' },
        onClick: () => {
          ModalDialog.show({
            title: 'Delete Session',
            message: 'Are you sure you want to delete this smoke session?',
            buttons: [{
              label: messages[language]?.cancel || 'Cancel',
              onClick: () => ModalDialog.close(),
            }, {
              label: 'Delete',
              style: { color: 'rgb(239, 81, 100)' },
              color: 'danger',
              onClick: () => {
                props.deleteSession(props.session);
                ModalDialog.close();
              },
            }],
            onClose: () => ModalDialog.close(),
          });
        },
      }]]);
    } else {
      setMoreOptions([...moreOptions, {
        content: 'Report Post',
        value: 'report',
        onClick: () => {
          console.log('TODO Send email to our admins with the details?');
        },
      }]);
    }
    if (window.analytics && props.session && props.session.cigar) {
      window.analytics.track('Cigar Impressions', {
        cigar_id: props.session.cigar.id,
        user_id: props.auth && props.auth.user && props.auth.user.id,
      });
    }
  }, []);

  const getImage = () => {
    const { session } = props;
    let imageUrl = darkMode ? PlaceholderDark.band : Placeholder.band;

    if (session.image_url) {
      imageUrl = session.image_url;
    } else if (session.scan.image_url) {
      imageUrl = session.scan.image_url;
    }

    return imageUrl;
  };

  const userName = () => {
    const { session } = props;
    if (session.scan.user.alias) {
      return session.scan.user.alias;
    }
    if (session.scan.user.full_name) {
      return session.scan.user.full_name;
    }
    return `${session.scan.user.first_name} ${session.scan.user.last_name}`;
  };

  const getTitle = () => {
    const { session } = props;
    let title = session.scan.cigar.name || '';
    if (session.vitola) {
      title += ` ${session.vitola.formatted_name}`;
    }
    return title.trim();
  };

  const showCigarDetail = (cigar) => {
    if (cigar && cigar.id) {
      if (window.analytics) {
        window.analytics.track('Cigar Clicks', {
          cigar_id: cigar.id,
          user_id: props.auth && props.auth.user && props.auth.user.id,
        });
      }
      props.history.push({
        pathname: `/cigars/${hashids.encode(cigar.id)}`,
        state: { cigar },
      });
    }
  };

  const trackVenueClick = (venue) => {
    if (window.analytics) {
      window.analytics.track('Venue Clicks', {
        venue_id: venue.id,
        user_id: props.auth && props.auth.user && props.auth.user.id,
        impression_type: 'user-generated',
      });
    }
  };

  const renderHeader = () => {
    const { session } = props;
    return (
      <div
        style={{
          display: 'flex',
          padding: 5,
        }}
        className="feed-session-card-header"
      >
        <Link to={`/users/${hashids.encode(session.scan.user.id)}`}>
          <Avatar
            src={session.scan.user.image_url && session.scan.user.image_url.replace('/users/profile/', '/users/profile/56x56/')}
            style={{
              height: 28,
              width: 28,
              margin: '8px 12px',
            }}
            alt={session.scan.user.alias || session.scan.user.display_name || 'Boxpressd User'}
          >
            {session.scan.user.first_name.charAt(0)}
          </Avatar>
        </Link>
        <div
          style={{ flex: 1 }}
          itemProp="author"
          itemScope
          itemType="https://schema.org/Person"
        >
          <Link to={`/users/${hashids.encode(session.scan.user.id)}`}>
            <div
              style={{
                fontWeight: 600,
                color: '#2b2b2b !important',
              }}
              itemProp="name"
            >
              {userName()}
            </div>
          </Link>
          <div style={{ fontSize: 12 }}>
            {`${messages[language]?.smoked || 'Smoked'} ${renderTimestamp(session.smoked_timestamp || session.timestamp)}${session.duration ? `${formatDuration(session.duration)}` : ''}`}
          </div>
        </div>
        {!props.compact && (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="More Options"
            style={{ marginRight: 5 }}
            onClick={() => {
              BottomSheet.show({
                items: moreOptions,
              });
            }}
          >
            <Icon
              name="more-horizontal"
              style={{
                height: 22,
                width: 22,
              }}
            />
          </IconButton>
        )}
      </div>
    );
  };

  const renderMedia = () => (
    <div className="image">
      {renderHeader()}
      {renderInnerMedia()}
    </div>
  );

  const renderInnerMedia = () => {
    const { session } = props;
    if (session.media && session.media.length > 0) {
      // console.log('Session media:');
      // console.log(session.media);
      return (
        <MediaGrid
          media={session.media.map((media) => ({
            type: media.media_type,
            src: media.media_url,
          }))}
          direction="vertical"
          // direction={props.compact ? 'horizontal' : 'vertical'}
          lazyImages={session.media.map((media) => ({
            type: media.media_type,
            src: media.media_type !== 'video' ? Resize.size(media.media_url, {
              height: 100,
              width: 100,
              cropType: 'crop',
            }) : media.media_url,
          }))}
          thumbnailMedia={session.media.map((media) => ({
            type: media.media_type,
            src: media.media_type !== 'video' ? Resize.size(media.media_url) : media.media_url,
          }))}
          onImageClick={() => showCigarDetail(session.scan.cigar)}
        />
      );
    }
    return (
      <div
        onClick={() => showCigarDetail(session.scan.cigar)}
        className="img"
        style={{ backgroundImage: `url("${getImage()}"), url("${darkMode ? PlaceholderDark.band : Placeholder.band}")` }}
      />
    );
  };

  const renderAverageRating = () => {
    const { session, avgRating, totalRatings } = props;
    let rating = avgRating;
    if (typeof rating === 'boolean') {
      rating = session.scan.cigar.avg_rating;
    }
    return (
      <div className="rating-star-container">
        <span>Average rating</span>
        <Rating
          initialRating={rating / 20}
          emptySymbol={<EmptySquareStar />}
          fullSymbol={<FilledSquareStar />}
          fractions={2}
          readonly
          style={{
            marginBottom: 10,
            marginLeft: 10,
          }}
        />
        <span style={{ marginLeft: 10 }}>
          ({totalRatings || session.scan.cigar.total_ratings || 0})
        </span>
      </div>
    );
  };

  const renderRating = () => {
    const { session } = props;
    const advancedRating = session.advance_rating;
    const { width } = window.screen;
    return (
      <div className="rating-star-container" itemProp="reviewRating" itemScope itemType="https://schema.org/Rating">
        <meta itemProp="bestRating" content="100" />
        <Row style={{ maxWidth: 500 }}>
          <Col
            style={{
              textAlign: 'center',
              maxWidth: width > 320 ? 120 : 100,
              borderRight: '1px solid #d2d2d2',
              padding: width > 320 ? 'inherit' : 0,
            }}
          >
            <div
              style={{
                fontSize: 50,
                maxHeight: 75,
              }}
              itemProp="ratingValue"
            >
              {parseInt(advancedRating.rating * 20)}
            </div>
            <Rating
              initialRating={advancedRating.rating}
              emptySymbol={<EmptySquareStar />}
              fullSymbol={<FilledSquareStar />}
              fractions={2}
              readonly
              style={{
                color: 'rgb(214, 194, 144)',
                marginBottom: 10,
              }}
            />
          </Col>
          <Col>
            <Row>
              <Col style={{ maxWidth: 110 }}>
                <div>{messages[language]?.flavor || 'Flavor'}</div>
              </Col>
              <Col>
                <div
                  style={{
                    minWidth: 90,
                    textAlign: 'right',
                  }}
                >
                  <Rating
                    initialRating={advancedRating.flavor || advancedRating.rating}
                    emptySymbol={<EmptySquareStar />}
                    fullSymbol={<FilledSquareStar />}
                    fractions={2}
                    readonly
                    style={{ color: 'rgb(214, 194, 144)' }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ maxWidth: 110 }}>
                <div>{messages[language]?.draw || 'Draw'}</div>
              </Col>
              <Col>
                <div
                  style={{
                    minWidth: 90,
                    textAlign: 'right',
                  }}
                >
                  <Rating
                    initialRating={advancedRating.draw || advancedRating.rating}
                    emptySymbol={<EmptySquareStar />}
                    fullSymbol={<FilledSquareStar />}
                    fractions={2}
                    readonly
                    style={{ color: 'rgb(214, 194, 144)' }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ maxWidth: 110 }}>
                <div>{messages[language]?.burn || 'Burn'}</div>
              </Col>
              <Col>
                <div
                  style={{
                    minWidth: 90,
                    textAlign: 'right',
                  }}
                >
                  <Rating
                    initialRating={advancedRating.burn || advancedRating.rating}
                    emptySymbol={<EmptySquareStar />}
                    fullSymbol={<FilledSquareStar />}
                    fractions={2}
                    readonly
                    style={{ color: 'rgb(214, 194, 144)' }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ maxWidth: 110 }}>
                <div>{messages[language]?.appearance || 'Appearance'}</div>
              </Col>
              <Col>
                <div
                  style={{
                    minWidth: 90,
                    textAlign: 'right',
                  }}
                >
                  <Rating
                    initialRating={advancedRating.appearance || advancedRating.rating}
                    emptySymbol={<EmptySquareStar />}
                    fullSymbol={<FilledSquareStar />}
                    fractions={2}
                    readonly
                    style={{ color: 'rgb(214, 194, 144)' }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const renderComment = () => (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: 10,
        }}
        onClick={() => showCigarDetail(props.session.scan.cigar)}
      >
        <Icon
          name={['fas', 'quote-left']}
          vendor="fa"
          style={{
            color: '#d9d9d9',
            width: 24,
          }}
        />
        <div
          style={{
            flex: 'auto',
            marginLeft: 6,
            marginBottom: 10,
          }}
          itemProp="reviewBody"
        >
          <Linkify>
            {showTranslation && translation ? translation : props.session.comment}
          </Linkify>
        </div>
      </div>
      {translation && (
        <Chip
          color="default"
          onClick={() => setShowTranslation(!showTranslation)}
          icon={<Icon name="globe" />}
          label={<span style={{ marginLeft: 8 }}>{showTranslation ? 'Show Original' : 'Show Translation'}</span>}
        />
      )}
    </div>
  );

  const renderPurchaseLocation = () => {
    const purchaseLocation = props.session.bought_from;
    // FIXME Using virtual lists, can't this be inaccurate? Disabling for now to avoid issues with Segment
    // if (window.analytics) {
    //   window.analytics.track('Venue Impressions', {
    //     venue_id: purchaseLocation.id,
    //     user_id: props.auth && props.auth.user && props.auth.user.id,
    //     impression_type: 'user-generated',
    //   });
    // }
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 8,
        }}
      >
        <Link
          to={`/venues/${purchaseLocation.hash_id}`}
          style={{ display: 'flex' }}
          onClick={() => trackVenueClick(purchaseLocation)}
        >
          <Avatar
            src={Venue.getImage(purchaseLocation)}
            style={{
              height: 20,
              width: 20,
              marginRight: 10,
            }}
            alt={purchaseLocation.name}
          >
            {purchaseLocation.name.charAt(0)}
          </Avatar>
          <div style={{ flex: 'auto' }}>
            {`${messages[language]?.bought_at || 'Bought at'} ${purchaseLocation.name}`}
          </div>
        </Link>
      </div>
    );
  };

  const renderSmokeLocation = () => {
    const smokeLocation = props.session.smoke_venue;
    // FIXME Using virtual lists, can't this be inaccurate? Disabling for now to avoid issues with Segment
    // if (window.analytics) {
    //   window.analytics.track('Venue Impressions', {
    //     venue_id: smokeLocation.id,
    //     user_id: props.auth && props.auth.user && props.auth.user.id,
    //     impression_type: 'user-generated',
    //   });
    // }
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 8,
        }}
      >
        <Link
          to={`/venues/${smokeLocation.hash_id}`}
          style={{ display: 'flex' }}
          onClick={() => trackVenueClick(smokeLocation)}
        >
          <Avatar
            src={Venue.getImage(smokeLocation)}
            style={{
              height: 20,
              width: 20,
              marginRight: 10,
            }}
            alt={smokeLocation.name}
          >
            {smokeLocation.name.charAt(0)}
          </Avatar>
          <div style={{ flex: 'auto' }}>
            {`${messages[language]?.smoked_at || 'Smoked at'} ${smokeLocation.name}`}
          </div>
        </Link>
      </div>
    );
  };

  const renderDrinkPairing = () => {
    const drinkPairing = props.session.drink_pairing;
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 8,
        }}
      >
        <Avatar
          src={Drink.getImage(drinkPairing)}
          style={{
            height: 20,
            width: 20,
            marginRight: 10,
          }}
          alt={drinkPairing.name}
        >
          {drinkPairing.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 'auto' }}>
          {`${messages[language]?.paired_with || 'Paired with'} ${drinkPairing.name}`}
        </div>
      </div>
    );
  };

  const sessionImage = () => {
    if (session.media && session.media.length) {
      for (let i = 0; i < session.media.length; i++) {
        const m = session.media[i];
        if (m.media_type === 'image') {
          return Resize.size(m.media_url);
        } if (m.media_type === 'video' && m.media_url.indexOf('.mp4') !== -1) {
          // INFO For now, only mp4 videos are generated (doesn't matter the original format) and converted to still images
          return Resize.size(m.media_url.replace('.mp4', '.jpg'));
        }
      }
    }
    if (session.scan && session.scan.image_url) {
      return Resize.size(session.scan.image_url);
    }
    return undefined;
  };

  const { session, showTitle } = props;
  const hasMedia = session.image_url || session.video_url || session.scan.image_url || (session.media && session.media.length > 0);
  return (
    <div
      className={`feed-card-item ${props.paddedBottom ? 'card-padded-bottom' : ''}`}
      itemProp="review"
      itemScope
      itemType="https://schema.org/Review"
    >
      {(hasMedia) && renderMedia()}
      <div className="content">
        {!hasMedia && renderHeader()}
        <div
          className="content-right"
          style={{
            width: '100%',
            minHeight: isMobile ? 285 : 230,
            paddingTop: 1,
          }}
        >
          <div
            style={{ display: 'none' }}
            itemType="https://schema.org/Product"
            itemProp="itemReviewed"
            itemScope
          >
            <span itemProp="name">{session.scan.cigar.full_name}</span>
            <div
              itemType="https://schema.org/AggregateRating"
              itemProp="aggregateRating"
              itemScope
            >
              <meta itemProp="bestRating" content="100" />
              <span itemProp="ratingValue">{session.scan.cigar.avg_rating}</span>
              <span itemProp="ratingCount">{props.totalRatings || session.scan.cigar.total_ratings || 0}</span>
            </div>
          </div>
          {showTitle && (
            <div style={{ display: 'flex' }} onClick={() => showCigarDetail(session.scan.cigar)}>
              <Avatar
                className="avatar-contained"
                src={Cigar.getBandImage(session.scan.cigar)}
                alt={session.scan.cigar.full_name}
                style={{
                  height: 60,
                  width: 60,
                  margin: 10,
                }}
              >
                <img
                  src={darkMode ? PlaceholderDark.band : Placeholder.band}
                  alt={session.scan.cigar.full_name}
                  style={{
                    height: 60,
                    width: 60,
                  }}
                />
              </Avatar>
              <div style={{ flex: 1 }} itemProp="itemReviewed" itemScope>
                <div
                  style={{
                    fontSize: 12,
                    marginTop: 10,
                  }}
                  id="cigar-brand"
                >
                  {session.scan.cigar.brand}
                </div>
                <h3 className="link" style={{ marginTop: 0 }} itemProp="name">{getTitle()}</h3>
                <div>{props.avgRating && renderAverageRating()}</div>
              </div>
            </div>
          )}
          {!showTitle && <div>{props.avgRating && renderAverageRating()}</div>}
          {session.advance_rating && renderRating()}
          {session.comment && renderComment()}
          {session.bought_from && renderPurchaseLocation()}
          {session.smoke_venue && renderSmokeLocation()}
          {session.drink_pairing && renderDrinkPairing()}
        </div>
        <FeedCardActions
          postId={session.id}
          commentType="session"
          commentRoute="sessions"
          commentCount={props.commentCount}
          commentOnClick={props.compact ? () => {
            // FIXME Just pass it no matter what?
            if (typeof props.onCommentClick === 'function') {
              props.onCommentClick();
            }
          } : null}
          likeCount={props.likeCount}
          onLikedToggle={(liked) => {
            console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          onPostComment={(comment, parentId) => {
            console.log('Comment posted...');
            console.log(comment);
            axios.post(`${config.apiEndPoint}/sessions/${session.id}/comments`, {
              session_id: session.id,
              // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
              comment: comment.replace('<br>', ''),
              user_id: props.auth.user.id,
              parent_id: parentId,
              comment_timestamp: new Date().toISOString(),
            });
          }}
          onShare={() => ShareIntent.share(new ShareItem({
            title: session.scan.cigar.full_name,
            text: `View my latest smoke session notes for ${session.scan.cigar.full_name} on Cigars Near Me`,
            path: 'cigar',
            route: `/cigars/${hashids.encode(session.scan.cigar.id)}?sessionId=${hashids.encode(session.id)}`,
            image: sessionImage(),
          }))}
        />
      </div>
    </div>
  );
}

FeedSessionCard.propTypes = {
  session: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  avgRating: PropTypes.bool || PropTypes.number,
  totalRatings: PropTypes.number,
  compact: PropTypes.bool,
};

FeedSessionCard.defaultProps = {
  compact: false,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
    deleteSession: (session) => dispatch(deleteSessionAction(session)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedSessionCard));
