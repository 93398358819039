import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { Badge } from 'reactstrap';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import VideoSource from '../VideoSource';
import { renderTimestamp } from '../../utils/formatting';
import Icon from '../../components/Icon';
import BottomSheet from '../bottom-sheet';
import { addToast as actionAddToast } from '../../actions';
import MediaGrid from '../media-grid';
import './style.scss';
import FeedCardActions from '../FeedCardActions';
import RichCard from '../RichCard';
import ModalDialog from '../ModalDialog';
import { language, messages } from '../../utils/localeUtils';
import { removeFromFeed, removePost } from '../../pages/GroupPostEditor/actions';
import { GroupStore } from '../../stores';
import Cigar from '../../models/Cigar';
import { Resize } from '../../utils/imageUtils';

const hashids = new Hashids('', 12);
const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

// FIXME All of these cards should extend from a top level one that handles all the common things like saving items
function FeedGroupPostCardList({ post, compact, onCommentClick, auth, history, deletePost, showBadge, showComments }) {
  const [moreOptions, setMoreOptions] = useState([]);

  useEffect(() => {
    console.log('Group Post In Card:');
    console.log(post);
    // FIXME Can this be simplified?
    if ((post && parseInt(post.user_id)) === (auth && auth.user && parseInt(auth.user.id))) {
      setMoreOptions([...moreOptions, ...[{
        content: 'Edit Post',
        icon: 'edit',
        value: 'edit',
        onClick: () => {
          history.push({
            pathname: `/groups/${hashids.encode(post.group_id)}`,
            search: `action=post&itemId=${post.id}`,
            state: { post: JSON.parse(JSON.stringify(post)) },
          });
        },
      }, {
        content: 'Delete Post',
        value: 'delete',
        icon: 'trash',
        style: { color: '#ef5164' },
        onClick: () => {
          ModalDialog.show({
            title: 'Delete Post',
            message: 'Are you sure you want to delete this post?',
            buttons: [{
              label: messages[language]?.cancel || 'Cancel',
              onClick: () => ModalDialog.close(),
            }, {
              label: 'Delete',
              style: { color: 'rgb(239, 81, 100)' },
              color: 'danger',
              onClick: async () => {
                // TODO Move to redux/helper? For now this is okay...
                // deletePost(post);
                GroupStore.update((s) => {
                  removePost(post, s);
                  removeFromFeed(post, s);
                });
                ModalDialog.close();
                await axios.delete(`${config.apiEndPoint}/groups/${post.group_id}/posts/${post.id}`);
              },
            }],
            onClose: () => ModalDialog.close(),
          });
        },
      }]]);
    } else {
      setMoreOptions([...moreOptions, {
        content: 'Report Post',
        value: 'report',
        onClick: () => {
          console.log('TODO Send email to our admins with the details?');
        },
      }]);
    }
  }, [post]);

  const renderHeader = () => {
    const { user, group } = post;
    const date = post.timestamp;
    return (
      <div style={{ display: 'flex', backgroundColor: '#ffffff', padding: 5 }}>
        <Link to={`/users/${hashids.encode(user.id)}`}>
          <Avatar src={user && user.image_url} style={{ height: 28, width: 28, margin: '8px 12px' }}>{user && user.first_name.charAt(0)}</Avatar>
        </Link>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>
            <Link to={`/users/${hashids.encode(user.id)}`}>
              {user.full_name}
            </Link>
            {/* FIXME If this is in the actual group profile, this should show the admin / moderator status instead of the group name */}
            {showBadge && <Badge pill style={{ marginLeft: 10, color: '#9f9f9f' }} className="card-header-badge">{group.name}</Badge>}
          </div>
          <div style={{ fontSize: 12 }}>
            {`Posted ${renderTimestamp(date)}`}
          </div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="More Options"
          style={{ marginRight: 5 }}
          onClick={() => {
            BottomSheet.show({
              items: moreOptions,
            });
          }}
        >
          <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
        </IconButton>
      </div>
    );
  };

  const renderInnerMedia = () => {
    if (post.media && post.media.length > 0) {
      if (post.media[0].media_type === 'video') {
        return (
          <VideoSource
            videoSrc={post.media[0].media_url}
            width="350"
            height="233"
            placeholder={post.media[0].thumbnail_url}
          />
        );
      }
      return (
        <MediaGrid
          media={post.media.map((media) => ({ type: media.media_type, src: media.media_url }))}
          lazyImages={post.media.map((media) => ({ type: media.media_type, src: Resize.size(media.media_url, { height: 100, width: 100, cropType: 'crop' }) }))}
          thumbnailMedia={post.media.map((media) => ({ type: media.media_type, src: Resize.size(media.media_url) }))}
          // onClick={() => showVenueDetail(post.venue)}
        />
      );
    }
    return null;
  };

  const renderMedia = () => (
    <div className="image">
      { renderHeader() }
      { renderInnerMedia() }
    </div>
  );

  // console.log('Got group post');
  // console.log(post);

  // FIXME Include the tagged users in the card? Do this for others like sessions and checkins, too?
  // const renderTaggedUsers = () => (
  //   <AvatarGroup>
  //     {post.tagged_users.slice(0, 6).map((user) => (
  //       <Avatar src={user.image_url} alt={user.full_name} style={{ height: 30, width: 30 }} />
  //     ))}
  //     {post.tagged_users.length > 6 && <Avatar style={{ height: 30, width: 30 }}><Icon name="more-horizontal" /></Avatar>}
  //   </AvatarGroup>
  // );

  // FIXME This isn't updating in the local card - it requires a page refresh. It also doesn't show it in the editor even after a refresh
  const renderCigar = (index) => {
    const cigar = post.cigars[index];
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 8,
        }}
      >
        <Link to={`/cigars/${cigar.hash_id}`} style={{ display: 'flex' }}>
          <Avatar
            src={Cigar.getBandImage(cigar)}
            style={{
              height: 20,
              width: 20,
              marginRight: 10,
            }}
            alt={cigar.full_name}
          >
            {cigar.full_name && cigar.full_name.charAt(0)}
          </Avatar>
          <div style={{ flex: 'auto' }}>
            {cigar.full_name}
          </div>
        </Link>
      </div>
    );
  };

  const renderVenue = (index) => {
    const venue = post.venues[index];
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 8,
        }}
      >
        <Link to={`/venues/${venue.hash_id}`} style={{ display: 'flex' }}>
          <Avatar
            src={venue.imageUrl}
            style={{
              height: 20,
              width: 20,
              marginRight: 10,
            }}
            alt={venue.name}
          >
            {venue.name.charAt(0)}
          </Avatar>
          <div style={{ flex: 'auto' }}>
            {venue.name}
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className="feed-card-item">
      { renderMedia() }
      <div className="content">
        <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
          <Link to={`/groups/${hashids.encode(post.group.id)}/posts/${hashids.encode(post.id)}`}>
            <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
          </Link>
          <div className="intro" style={{ margin: 0, lineBreak: 'auto' }}>
            <RichCard compact={post.media && post.media.length > 0}>{post.content}</RichCard>
          </div>
          {/* TODO This will be an array at some point... either a collapse or something similar */}
          {post.cigars && post.cigars.length > 0 && renderCigar(0)}
          {/* TODO This will be an array at some point... either a collapse or something similar */}
          {post.venues && post.venues.length > 0 && renderVenue(0)}
        </div>
        <FeedCardActions
          showComments={showComments}
          postId={post.id}
          commentType="groups_post"
          commentRoute="groups/posts"
          commentOnClick={compact ? () => {
            // FIXME Just pass it no matter what?
            if (typeof onCommentClick === 'function') {
              onCommentClick();
            }
          } : null}
          onLikedToggle={(liked) => {
            console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          onPostComment={(comment, parentId) => {
            console.log('Comment posted...');
            console.log(comment);
            axios.post(`${config.apiEndPoint}/groups/posts/${post.id}/comments`, {
              group_post_id: post.id,
              // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
              comment: comment.replace('<br>', ''),
              user_id: auth.user.id,
              parent_id: parentId,
              comment_timestamp: new Date().toISOString(),
            });
          }}
        />
      </div>
    </div>
  );
}

FeedGroupPostCardList.propTypes = {
  item: PropTypes.object.isRequired,
};

FeedGroupPostCardList.defaultProps = {
  showBadge: true,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedGroupPostCardList));
