import Avatar from '@material-ui/core/Avatar';
import AudioCard from 'audiocard';
import Linkify from 'linkify-react';
import { withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Hashids from 'hashids/cjs';
import { addToast as actionAddToast } from '../../actions';
import { renderTimestamp } from '../../utils/formatting';
import FeedCardActions from '../FeedCardActions';
import axios from 'axios';
import { config } from '../../settings';
import { Resize } from '../../utils/imageUtils';
const hashids = new Hashids('', 12);

function PodcastEpisodeCard({ episode, history, auth, post, commentCount, likeCount }) {
  const { title, author, author_image_url, image_url, excerpt, audio_clip_url, timestamp, link, external_url, itunes } = episode;
  useEffect(() => {
    if (window.analytics && post) {
      const { venue, brand } = post;

      window.analytics.track('Podcast Episode Impressions', {
        podcast_id: episode.id,
        business_id: post.business_id,
        business_type: post.business_type,
        user_id: auth && auth.user && auth.user.id,
      });

      if (venue) {
        window.analytics.track('Venue Impressions', {
          venue_id: venue.id,
          user_id: auth && auth.user && auth.user.id,
          impression_type: 'user-generated',
        });
      }

      if (brand) {
        window.analytics.track('Brand Impressions', {
          brand_id: brand.id,
          user_id: auth && auth.user && auth.user.id,
          impression_type: 'user-generated',
        });
      }
    }
  }, []);

  const getDisplayName = () => {
    const { venue, brand } = episode;
    const item = venue || brand;
    let name;
    console.log('Post:');
    console.log(episode);
    if ((episode.external_url && episode.external_url.indexOf('cigarsnearme') !== -1) || (episode.link && episode.link.indexOf('cigarsnearme') !== -1)) {
      name = 'Cigars 365';
    } else if (item) {
      name = item.name;
    }
    return name;
  };

  const getDisplayImage = () => {
    const { venue, brand } = episode;
    const item = venue || brand;
    let image;
    if ((episode.external_url && episode.external_url.indexOf('cigarsnearme') !== -1) || (episode.link && episode.link.indexOf('cigarsnearme') !== -1)) {
      image = 'https://cdn.cigarsnearme.com/assets/img/cigars-365-alexa.png';
    } else if (item) {
      image = item.profile_image_url || item.logo_image_url;
    }
    if (image) {
      image = Resize.size(image, { width: 28, height: 28, cropType: 'crop' });
    }
    return image;
  };

  const showProfile = () => {
    if (episode.business_type && (episode.venue || episode.brand)) {
      const { venue, brand } = episode;
      const item = venue || brand;
      if (window.analytics) {
        window.analytics.track(`${episode.business_type.charAt(0).toUpperCase()}${episode.business_type.substring(1, episode.business_type.length)} Clicks`, {
          [`${episode.business_type}_id`]: item && item.id,
          user_id: auth && auth.user && auth.user.id,
        });
      }
      history.push(`/${episode.business_type}s/${hashids.encode(item.id)}`);
    }
  };

  const showOriginalPodcast = (e) => {
    e.preventDefault();
    if (episode.external_url) {
      if (window.analytics) {
        const { venue, brand } = post;
        const item = venue || brand;

        window.analytics.track('Podcast Episode Clicks', {
          podcast_episode_id: episode.id,
          business_id: post.business_id,
          business_type: post.business_type,
          user_id: auth && auth.user && auth.user.id,
          url: episode.external_url,
        });

        window.analytics.track(`${episode.business_type.charAt(0).toUpperCase()}${episode.business_type.substring(1, episode.business_type.length)} Actions`, {
          [`${episode.business_type}_id`]: item && item.id,
          user_id: auth && auth.user && auth.user.id,
          type: 'view_podcast_episode',
          url: episode.external_url,
        });
      }
      window.location.href = episode.external_url;
    }
  };

  return (
    <div className="feed-card-item">
      <div className="image">
        {/* TODO Click to business? */}
        <div style={{ display: 'flex', backgroundColor: '#ffffff', padding: 5 }}>
          <Avatar
            src={getDisplayImage()}
            alt={getDisplayName()}
            style={{
              height: 28,
              width: 28,
              margin: '8px 12px',
            }}
            onClick={showProfile}
          >
            {getDisplayName().charAt(0)}
          </Avatar>
          <div style={{ flex: 1 }}>
            <div
              style={{
                fontWeight: 600,
                color: '#2b2b2b !important',
              }}
              onClick={showProfile}
            >
              {getDisplayName()}
            </div>
            <div style={{ fontSize: 12 }}>
              {`Posted ${renderTimestamp(timestamp)}`}
            </div>
          </div>
        </div>
        <div
          style={{
            minHeight: 120,
            float: 'none',
            display: 'inline',
          }}
        >
          {/* FIXME This seems too slow - clone it to see if it can be sped up and also include loading skeleton view */}
          <AudioCard title={title} art={image_url} source={audio_clip_url} />
        </div>
      </div>
      <div className="content" style={{ cursor: 'pointer' }}>
        <div className="content-right" onClick={showOriginalPodcast} style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 20 }}>
          {/* FIXME This isn't a good way to handle this - malicious users can inject tracking code or worse - I think we can just linkify URLs instead */}
          {/* <div dangerouslySetInnerHTML={{ __html: episode['content:encoded'] }} /> */}
          <Linkify>{excerpt}</Linkify>
        </div>
        <FeedCardActions
          postId={episode.id}
          commentType="podcast_episode"
          commentRoute="podcasts/episodes"
          commentCount={commentCount}
          likeCount={likeCount}
          onLikedToggle={(liked) => {
            console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          // commentOnClick={props.compact ? () => {
          //   // FIXME Just pass it no matter what?
          //   if (typeof props.onCommentClick === 'function') {
          //     props.onCommentClick();
          //   }
          // } : null}
          onPostComment={(comment, parentId) => {
            console.log('Comment posted...');
            console.log(comment);
            axios.post(`${config.apiEndPoint}/businesses/podcasts/episodes/${post.id}/comments`, {
              business_post_id: post.id,
              // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
              comment: comment.replace('<br>', ''),
              user_id: this.props.auth.user.id,
              parent_id: parentId,
              comment_timestamp: new Date().toISOString(),
            });
          }}
          // FIXME Keep this hidden if it's external? Or allow users to share them anyway? If so, it probably needs to link
          //  to the comment screen
          // onShare={() => ShareIntent.share(new ShareItem({
          //   title: session.scan.cigar.full_name,
          //   text: `View my latest smoke session notes for ${session.scan.cigar.full_name} on Cigars Near Me`,
          //   path: 'cigar',
          //   route: `/cigars/${hashids.encode(session.scan.cigar.id)}?sessionId=${hashids.encode(session.id)}`,
          //   image: sessionImage(),
          // }))}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PodcastEpisodeCard));
