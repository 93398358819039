import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Rating from 'react-rating';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import { saveItem, unsaveItem } from '../../pages/SavedItems/helper';
import Placeholder from '../../../config/placeholder.config';
import Icon from '../Icon';
import ActionIconGroup from '../ActionIconGroup';
import ShareItem from '../../models/ShareItem';
import ShareIntent from '../../utils/shareUtils';
import { renderTimestamp } from '../../utils/formatting';
import BottomSheet from '../bottom-sheet';
import { addToast as actionAddToast } from '../../actions';
const hashids = new Hashids('', 12);

export class FeedScanCard extends PureComponent {
  // FIXME These should be dynamic - for example, a user should be able to edit or delete their own session, and reporting themselves probably will never happen ha
  moreOptions = [{
    content: 'Save cigar to Try List',
    icon: 'bookmark',
    value: 'save',
    onClick: () => {
      const { cigar } = this.props.session.scan;
      const { auth } = this.props;
      const { user } = auth;
      saveItem(user, 'cigar', cigar, 'try_list');
      this.props.addToast({
        content: (<>Added '{cigar.full_name}' to Try List</>),
        duration: 6000,
      });
    },
  }, {
    content: 'Mark as Favorite',
    icon: 'heart',
    value: 'favorite',
    onClick: () => {
      const { cigar } = this.props.session.scan;
      const { auth } = this.props;
      const { user } = auth;
      saveItem(user, 'cigar', cigar, 'favorites');
      this.props.addToast({
        content: (<>Marked '{cigar.full_name}' as Favorite</>),
        duration: 6000,
      });
    },
  }, {
    content: 'Show Where To Buy',
    icon: 'shopping-cart',
    value: 'products',
    onClick: () => {
      const { cigar } = this.props.session.scan;
      this.props.history.push(`/cigars/${hashids.encode(cigar.id)}?tab=products`);
    },
  }, {
    content: 'Share',
    icon: 'share',
    value: 'share',
    onClick: () => {
      const { session } = this.props;
      const { cigar } = session.scan;
      ShareIntent.share(new ShareItem({
        title: cigar.full_name,
        path: 'cigar',
        route: `/cigars/${hashids.encode(cigar.id)}`,
        image: session.image_url,
      }));
    },
  }];

  constructor(props) {
    super(props);

    // FIXME Can this be simplified?
    if ((props.scan && props.scan.user && props.scan.user.id) === (props.auth && props.auth.user && props.auth.user.id)) {
      // TODO Do anything?
    } else {
      this.moreOptions.push({
        content: 'Report Post',
        value: 'report',
        onClick: () => {
          console.log('TODO Send email to our admins with the details?');
        },
      });
    }
  }

  getImage = () => {
    const { scan } = this.props;
    let imageUrl = Placeholder.cigar;

    if (scan.image_url) {
      imageUrl = scan.image_url;
    }

    return imageUrl;
  };

  showCigarDetail = (cigar) => {
    if (cigar && cigar.id) {
      this.props.history.push({ pathname: `/cigars/${hashids.encode(cigar.id)}`, state: { cigar } });
    }
  };

  showVenueDetail = (venue) => {
    if (venue && venue.id) {
      this.props.history.push({ pathname: `/venues/${hashids.encode(venue.id)}`, state: { venue } });
    }
  };

  toggleSavedItem = (save) => {
    const { cigar } = this.props.scan;
    const item = JSON.parse(JSON.stringify(cigar));
    const { auth } = this.props;
    const { user } = auth;

    console.log('Toggling saved cigar for state:');
    if (save) {
      console.log('save');
      saveItem(user, 'cigar', item, 'try_list');
    } else {
      console.log('unsave');
      unsaveItem(user, {
        item_id: item.id,
        type: 'try_list',
      });
    }
  };

  shareTo = (service) => {
    const { scan } = this.props;
    const { cigar } = scan;
    // FIXME Should be Branch.io link
    let url = `https://app.boxpressd.com/cigars/${hashids.encode(cigar.id)}`;
    if (service === 'facebook') {
      url = `https://www.facebook.com/sharer.php?u=${url}`;
    } else if (service === 'twitter') {
      url = `https://twitter.com/share?url=${url}&text=${cigar.full_name}`;
    } else if (service === 'reddit') {
      url = `https://reddit.com/submit?url=${url}&title=${cigar.full_name}`;
    } else if (service === 'pinterest') {
      url = `https://pinterest.com/pin/create/button/?url=${url}&media=${cigar.image_url}&description=${cigar.full_name}`;
    }

    const shareWindow = window.open(url, 'share-popup', 'height=350,width=600');
    if (shareWindow && shareWindow.focus) { shareWindow.focus(); }
    return false;
  };

  userName = () => {
    const { scan } = this.props;
    if (scan.user.alias) {
      return scan.user.alias;
    }
    if (scan.user.full_name) {
      return scan.user.full_name;
    }
    return `${scan.user.first_name} ${scan.user.last_name}`;
  };

  getTitle = () => {
    const { scan } = this.props;
    let title = scan.cigar ? scan.cigar.full_name : 'Pending...';
    // if (scan.vitola) {
    //   title += ` ${scan.vitola.formatted_name}`;
    // }
    return title;
  };

  renderActionIcons = () => (
    <ActionIconGroup
      comments={false}
      containerStyle={{ float: 'right', marginRight: isMobile ? 10 : 40, marginTop: isMobile ? -36 : 12, marginBottom: isMobile ? 'inherit' : 20 }}
      toggleSavedItem={this.toggleSavedItem}
      // itemSaved={this.state.isOnSavedList}
      shareTo={this.shareTo}
    />
  );

  renderHeader = () => {
    const { scan } = this.props;
    return (
      <div style={{ display: 'flex', padding: 5 }} className="feed-session-card-header">
        <Link to={`/users/${hashids.encode(scan.user.id)}`}>
          <Avatar src={scan.user.image_url} style={{ height: 28, width: 28, margin: '8px 12px' }} alt={scan.user.alias}>{scan.user.first_name.charAt(0)}</Avatar>
        </Link>
        <div style={{ flex: 1 }}>
          <Link to={`/users/${hashids.encode(scan.user.id)}`}>
            <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>{this.userName()}</div>
          </Link>
          <div style={{ fontSize: 12 }}>
            {`Matched ${renderTimestamp(scan.timestamp)}`}
          </div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="More Options"
          style={{ marginRight: 5 }}
          onClick={() => {
            BottomSheet.show({
              items: this.moreOptions,
            });
          }}
        >
          <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
        </IconButton>
      </div>
    );
  };

  renderMedia = () => (
    <div className="image">
      { this.renderHeader() }
      { this.renderInnerMedia() }
    </div>
  );

  renderInnerMedia = () => {
    const { scan } = this.props;
    return (
      <div onClick={() => this.showCigarDetail(scan.cigar)} className="img" style={{ backgroundImage: `url("${this.getImage()}"), url("${Placeholder.cigar}")` }} />
    );
  };

  renderAverageRating = () => {
    const { scan, avgRating, totalRatings } = this.props;
    let rating = avgRating;
    if (typeof rating === 'boolean') {
      rating = scan.cigar.avg_rating;
    }
    return (
      <div>
        <span>Average rating</span>
        <Rating
          initialRating={rating / 20}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          fractions={2}
          readonly
          style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginLeft: 10 }}
        />
        <span style={{ marginLeft: 10 }}>
          {totalRatings || scan.cigar.total_ratings || 0}
          {' '}
          ratings
        </span>
      </div>
    );
  };

  renderPurchaseLocation = () => {
    const purchaseLocation = this.props.session.bought_from;
    return (
      <div style={{ display: 'flex', marginTop: 8 }}>
        <span onClick={() => this.showVenueDetail(purchaseLocation)} style={{ display: 'flex' }}>
          <Avatar src={purchaseLocation.image_url} style={{ height: 20, width: 20, marginRight: 10 }} alt={purchaseLocation.name}>{purchaseLocation.name.charAt(0)}</Avatar>
          <div style={{ flex: 'auto' }}>
            {`Bought at ${purchaseLocation.name}`}
          </div>
        </span>
      </div>
    );
  };

  render() {
    const { scan, showTitle } = this.props;
    const hasMedia = typeof scan.image_url !== 'undefined' && scan.image_url !== null;
    return (
      <div className="feed-card-item">
        { (hasMedia) && this.renderMedia() }
        <div className="content">
          { !hasMedia && this.renderHeader() }
          <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
            {scan.cigar ? (
              <h3 className="link" onClick={() => this.showCigarDetail(scan.cigar)}>{showTitle && this.getTitle() }</h3>
            ) : <h3>{showTitle && this.getTitle() }</h3>}
          </div>
        </div>
      </div>
    );
  }
}

FeedScanCard.propTypes = {
  scan: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  avgRating: PropTypes.bool || PropTypes.number,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedScanCard));
